<template>
  <div class="container my-24 my-lg-40">
    <div class="row mb-24">
      <header class="col">
        <h2>Ваш список тестов</h2>
      </header>
    </div>

    <section
      v-if="instruction.isComplete"
      class="row align-items-center justify-content-between mb-16"
    >
      <div class="col-12 col-lg-auto order-lg-1 mb-8 mb-lg-16">
        <v-card
          tile
          class="px-16 d-flex align-items-center justify-content-between cursor-pointer"
          @click="instruction.isShow = !instruction.isShow"
        >
          <div class="py-16 pr-16 text-nowrap">
            <b class="text-color-primary">
              {{ instruction.isShow ? 'Скрыть' : 'Читать' }} инструкцию к тексту
            </b>
          </div>

          <div>
            <v-icon-svg
              class="a-flip-y svg-stroke-ui-main-1"
              :class="{'a-flip-y_active': instruction.isShow}"
              fill="none"
              view-box="0 0 11 5"
              title="svg-icon-chevron"
              width="12"
              height="12"
            >
              <svg-icon-chevron/>
            </v-icon-svg>
          </div>
        </v-card>
      </div>

      <article
        class="col-12 order-lg-3 mb-lg-24"
        v-if="instruction.isShow"
      >
        <v-card class="px-16 py-32 p-lg-32 ul ul_type_lines">
          <p>Добрый день!</p>

          <p>
            Вы приглашены к прохождению теста управленческого потенциала.
            Данный тест измеряет качества, прогнозирующие успех человека
            в новых рабочих условиях и обстоятельствах.
          </p>

          <hr class="my-24">

          <p>
            <strong>
              Пожалуйста, учитывайте следующие особенности теста,
              связанные с ограничением времени:
            </strong>
          </p>

          <ul>
            <li>
              <strong>Между разделами можно делать паузы.</strong>
            </li>
            <li>
              <strong>Внутри большинства разделов паузы делать нельзя</strong> — время на их прохождение ограничено.
              Если Вы закроете
              тест во время прохождения одного из блоков, таймер продолжет идти.
            </li>
            <li>
              <strong>Таймер расположен на виджете с правой стороны</strong>, рядом с тестовой частью.
            </li>
            <li>
              <strong>Количество минут на прохождение теста указывается
                в дополнительных инструкциях в каждом разделе.</strong>
            </li>
            <li>
              <strong>Общее время прохождения теста — 120 минут.</strong>
            </li>
            <li>
              <strong>Приготовьте заранее ручку / карандаш, бумагу, калькулятор.</strong>
              В одном разделе теста необходимо производить математические вычисления.
            </li>
            <li>
              <strong>Ответ засчитывается только при нажатии на кнопку «Ответить».</strong>
              Таким образом, Вы подтверждаете свой ответ.
            </li>
          </ul>

          <hr class="my-24">

          <p>
            Если у Вас возникнут вопросы или затруднения в ходе работы с системой
            — напишите в службу поддержки в вашем личном кабинете.
          </p>

          <p class="mb-0 mt-24">
            <strong>Желаем Вам удачи!</strong>
          </p>
        </v-card>
      </article>

      <div class="col-12 col-lg-auto order-lg-2 mb-8 mb-lg-16 mt-8 mt-lg-0">
        <v-card
          tile
          class="p-16 d-flex align-items-center"
        >
          <v-icon-svg
            class="svg-stroke-ui-3 mr-8"
            view-box="0 0 21 24"
            title="svg-icon-clock"
            width="1.5rem"
            height="1.5rem"
          >
            <svg-icon-clock></svg-icon-clock>
          </v-icon-svg>

          <span class="d-inline-block">
                  Сейчас таймер отключен, можете отдохнуть, если хотите
                </span>
        </v-card>
      </div>
    </section>

    <section
      v-else
      class="row mb-32"
    >
      <div class="col">
        <v-card
          tile
          class="px-16 px-xl-32"
        >
          <div class="row align-items-center">
            <div class="col">
              <p class="h4 my-16">Инструкция к тесту</p>
            </div>

            <div class="col-auto">
              <v-button
                @click.stop="instruction.isComplete = true"
              >
                Читать
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </section>

    <section class="row">
      <div
        v-for="test of testList"
        :key="test.id"
        class="col-12 col-lg-6 mb-16 align-items-stretch"
      >
        <v-card class="py-32 px-16 p-xl-32 h-100">
          <div class="row align-items-center mb-16">
            <div class="col-auto">
              <v-icon-svg
                view-box="0 0 44 44"
                width="44"
                height="44"
              >
                <component :is="`SvgIconWa${test.icon}`"/>
              </v-icon-svg>
            </div>
            <div class="col">
              <h4>{{ test.title }}</h4>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <p v-html="test.text"/>
            </div>
          </div>

          <hr class="mb-32"/>

          <div
            v-for="(item, i) of test.tests"
            :key="i"
            class="row align-items-center mt-16"
          >
            <div class="col">
              <b :class="{'text-color-text-secondary':item.state === 'done'}">
                {{ item.title }}
              </b>

              <div class="d-sm-none mt-4">
                <v-icon-svg
                  class="svg-stroke-primary"
                  view-box="0 0 21 24"
                  title="svg-icon-clock"
                  width="16"
                  height="16"
                >
                  <svg-icon-clock/>
                </v-icon-svg>
                {{ item.time }}
              </div>
            </div>

            <div class="col d-none d-sm-block text-right">
              <v-icon-svg
                class="svg-stroke-primary"
                view-box="0 0 21 24"
                title="svg-icon-clock"
                width="16"
                height="16"
              >
                <svg-icon-clock/>
              </v-icon-svg>
              {{ item.time }}
            </div>

            <div
              v-if="instruction.isComplete"
              class="col text-right"
            >
              <div
                v-if="item.state === 'done'"
                class="text-nowrap"
              >
                <v-icon-svg
                  class="mr-8 svg-stroke-primary"
                  view-box="0 0 11 8"
                  title="svg-icon-checked"
                  width="11"
                  height="8"
                >
                  <svg-icon-checked/>
                </v-icon-svg>

                <strong class="text-color-primary">Пройдено!</strong>
              </div>

              <v-button v-if="item.state === 'open'">
                Начать
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </section>
  </div>
</template>

<script>
import {
  VButton,
  VCard,
  VIconSvg
} from '@components/base'
import SvgIconChevron from '@components/icons/SvgIconChevron'
import SvgIconChecked from '@components/icons/SvgIconChecked'
import SvgIconClock from '@components/icons/SvgIconClock'
import SvgIconWaBuilding from '@components/icons/WA/SvgIconWaBuilding'
import SvgIconWaDiscontent from '@components/icons/WA/SvgIconWaDiscontent'
import SvgIconWaFlask from '@components/icons/WA/SvgIconWaFlask'
import SvgIconWaNegotiation from '@components/icons/WA/SvgIconWaNegotiation'
import SvgIconWaResentment from '@components/icons/WA/SvgIconWaResentment'

export default {
  name: 'SWaTestList',

  components: {
    SvgIconChevron,
    SvgIconChecked,
    SvgIconClock,
    SvgIconWaBuilding,
    SvgIconWaDiscontent,
    SvgIconWaFlask,
    SvgIconWaNegotiation,
    SvgIconWaResentment,
    VButton,
    VCard,
    VIconSvg
  },

  data () {
    return {
      instruction: {
        isComplete: false,
        isShow: false
      },
      testList: [
        {
          id: '1',
          title: 'Химическая промышленность',
          icon: 'Flask',
          text: 'Вы столкнетесь с невиданными раннее трудностями. С ребенком, который не укладывается спать, со свекровью, которая не уезжает домой, с соседом-ремонтником. И все это —  в один день.',
          tests: [
            {
              title: 'Подготовка',
              time: '20 мин',
              state: 'done'
            },
            {
              title: 'Дискуссия',
              time: '30 мин',
              state: 'done'
            }
          ]
        },
        {
          id: '2',
          title: 'Высокоуровневые переговоры',
          icon: 'Negotiation',
          text: 'Вы столкнетесь с невиданными раннее трудностями. С ребенком, который не укладывается спать, со свекровью, которая не уезжает домой, с соседом-ремонтником. И все это —  в один день.',
          tests: [
            {
              title: 'Подготовка',
              time: '20 мин',
              state: 'done'
            },
            {
              title: 'Дискуссия',
              time: '30 мин',
              state: 'open'
            },
            {
              title: 'Интервью с наблюдателем',
              time: '10 мин',
              state: null
            }
          ]
        },
        {
          id: '3',
          title: 'Строительство поселка',
          icon: 'Building',
          text: 'Вы столкнетесь с невиданными раннее трудностями. С ребенком, который не укладывается спать, со свекровью, которая не уезжает домой, с соседом-ремонтником. И все это —  в один день.',
          tests: [
            {
              title: 'Подготовка',
              time: '20 мин',
              state: null
            }
          ]
        },
        {
          id: '4',
          title: 'Обиженный подчиненный',
          icon: 'Resentment',
          text: 'Вы столкнетесь с невиданными раннее трудностями. С ребенком, который не укладывается спать, со свекровью, которая не уезжает домой, с соседом-ремонтником. И все это —  в один день.',
          tests: [
            {
              title: 'Подготовка',
              time: '20 мин',
              state: null
            },
            {
              title: 'Дискуссия',
              time: '30 мин',
              state: null
            }
          ]
        },
        {
          id: '5',
          title: 'Недовольный клиент',
          icon: 'Discontent',
          text: 'Вы столкнетесь с невиданными раннее трудностями. С ребенком, который не укладывается спать, со свекровью, которая не уезжает домой, с соседом-ремонтником. И все это —  в один день.',
          tests: [
            {
              title: 'Подготовка',
              time: '20 мин',
              state: null
            }
          ]
        }
      ]
    }
  }
}
</script>
